<template>
    <div>
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box d-flex align-items-center justify-content-between">
                    <h4 class="mb-0 font-size-18">Tổng quan</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <address-filter @change="getData" />
        <div class="row">
            <div class="col-xl-6">
                <div class="card overflow-hidden">
                    <div class="bg-primary bg-soft">
                        <div class="row">
                            <div class="col-12">
                                <div class="text-muted mb-0 p-3">
                                    <h5 class="style-size" style="color: white">Chào mừng đến với trang quản trị !</h5>
                                    <p style="color: white">Kết nối - Xã thông minh</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="pt-4">
                                    <h5 class="font-size-15 text-truncate">{{ getUser.name }} </h5>
                                    <p class="text-muted mb-0 text-truncate">{{ getUser.phone }}</p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="pt-4">
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-muted mb-0">Phản ánh chờ bạn xử lý</p>
                                            <h5 class="font-size-15">{{ userSentimentsWaiting }}</h5>
                                        </div>
                                        <div class="col-12">
                                            <p class="text-muted mb-0">Phản ánh bạn đang xử lý</p>
                                            <h5 class="font-size-15">{{ userSentimentsProcessing }}</h5>
                                        </div>
                                        <div class="col-12">
                                            <p class="text-muted mb-0">Phản ánh bạn đã hoàn thành</p>
                                            <h5 class="font-size-15">{{ userSentimentsProcessed +
                                                    userSentimentsNoProcessing
                                            }}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6">
                <div class="row style-height-total">
                    <div class="col-md-12">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium">Tổng số phản ánh</p>
                                        <h4 class="mb-0">{{ totalSentiments }}</h4>
                                    </div>
                                    <div class="flex-shrink-0 align-self-center">
                                        <div class="mini-stat-icon avatar-sm rounded-circle bg-primary">
                                            <span class="avatar-title">
                                                <i class="bx bx-mail-send font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row style-height-total">
                    <div class="col-md-12">
                        <div class="card mini-stats-wid">
                            <div class="card-body">
                                <div class="d-flex">
                                    <div class="flex-grow-1">
                                        <p class="text-muted fw-medium">Tổng số đánh giá</p>
                                        <h4 class="mb-0">{{ totalRates }}</h4>
                                    </div>
                                    <div class="flex-shrink-0 align-self-center ">
                                        <div class="avatar-sm rounded-circle bg-primary mini-stat-icon">
                                            <span class="avatar-title rounded-circle bg-primary">
                                                <i class="bx bx-copy-alt font-size-24"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <b-col xl="6">
                <b-card>
                    <label>Thống kê phản ánh theo trạng thái</label>
                    <apexchart height="300" type="donut" name="test" :options="chartOptions" ref="series"
                        :series="series">
                    </apexchart>
                </b-card>
            </b-col>
            <b-col xl="6">
                <b-card>
                    <label>Thống kê số lượng phản ánh theo danh mục</label>
                    <apexchart ref="seriesCate" height="300" type="donut" name="test2" :options="chartOptionsCate"
                        :series="seriesCate">
                    </apexchart>
                </b-card>
            </b-col>
        </div>
        <div class="row">
            <b-col xl="6">
                <b-card>
                    <label>Biểu đồ số lượng phản ánh theo ngày</label>
                    <apexchart height="350" type="line" name="lineCharts1" :options="lineChartOptions"
                        ref="seriesLineSentiments" :series="seriesLineSentiments">
                    </apexchart>
                </b-card>
            </b-col>
            <b-col xl="6">
                <b-card>
                    <label>Biểu đồ số lượng đánh giá theo ngày</label>
                    <apexchart ref="seriesLineRates" height="350" type="line" name="lineCharts2"
                        :options="lineChartOptions2" :series="seriesLineRates">
                    </apexchart>
                </b-card>
            </b-col>
        </div>
        <!-- end row -->
    </div>
</template>

<script>
import {
    httpClient
} from "../../../_utils/httpClient";
import {
    Base64
} from 'js-base64'
import addressFilter from '../../../components/filter/address-filter'

export default {
    page: {
        title: "Dashboard",
        meta: [{
            name: "description",
            content: ""
        }]
    },
    computed: {
        getUser() {
            let userData = localStorage.getItem('token').split('.')[1]
            return JSON.parse(Base64.decode(userData))
        }
    },
    components: {
        addressFilter
    },
    data() {
        return {
            totalRates: 0,
            totalSentiments: 0,
            userSentiments: 0,
            userSentimentsNoProcessing: 0,
            userSentimentsProcessed: 0,
            userSentimentsProcessing: 0,
            userSentimentsWaiting: 0,
            yearSentiment: "",
            title: "Dashboard",
            series: [],
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                legend: {
                    position: "right",
                },
                colors: ["#f4bc11", "#00ff00", "#0f87fa", "#ff5144", "#e81123"],
            },
            seriesCate: [],
            chartOptionsCate: {
                labels: ['123'],
                chart: {
                    type: 'donut',
                }, 
                legend: {
                    position: "right",
                },
                colors: ["#40e0d0", "#fc8eac", "#746cc0", "#2a4679", "#00b294", "#c5c3a1", "#fff100", "#ff8c00", "#e81123"],
            },
            seriesLineSentiments: [{
                name: "Phản ánh",
                data: []
            }],
            lineChartOptions: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                }
            },
            lineChartOptions2: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                }
            },
            seriesLineRates: [{
                name: 'Đánh giá',
                data: []
            }]

        };
    },
    methods: {
        async ChartsStatusGroup(province, district, ward) {
            let payload = {
                province,
                district,
                ward
            }
            for (let key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }
            let response = await httpClient.post(`statistic/sentiments-status`, {
                ...payload
            })
            let series = []
            let labels = []
            if (response.data.data) {
                response.data.data.forEach(el => {
                    series.push(el.count)
                    if (el.status === undefined || el.status === "") {
                        labels.push(`Đang chờ xử lý`)
                    } else {
                        labels.push(el.status)
                    }
                })
            }
            this.series = series
            this.chartOptions = {
                ...this.chartOptions,
                labels
            }
        },
        async getChartsCateGroup(province, district, ward) {
            let payload = {
                province,
                district,
                ward
            }
            for (let key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }
            let response = await httpClient.post(`statistic/sentiments-category`, {
                ...payload
            })
            let series = []
            let labels = []
            if (response.data.data) {
                response.data.data.forEach(el => {
                    series.push(el.count)
                    if (el._id.theLoai === undefined) {
                        labels.push(`Khác`)
                    } else {
                        labels.push(el._id.theLoai)
                    }
                })
            }
            this.seriesCate = series
            this.chartOptionsCate = {
                ...this.chartOptionsCate,
                ...{
                    labels
                }
            }
        },
        async getSeriesLineSentiments(province, district, ward) {
            let payload = {
                province,
                district,
                ward
            }
            for (let key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }
            let response = await httpClient.post(`statistic/sentiments-by-date`, {
                ...payload
            })
            let series = []
            let labels = []
            let revertDate = ""
            if (response.data.data) {
                response.data.data.forEach(el => {
                    let splitDate = (el._id).split("-");
                    this.yearSentiment = splitDate[0]
                    revertDate = splitDate[2] + "/" + splitDate[1]
                    series.push(el.count)
                    labels.push(revertDate)
                })
            }
            this.$refs.seriesLineSentiments.updateSeries([{
                data: series
            }])
            this.lineChartOptions = {
                ...this.lineChartOptions,
                ...{
                    xaxis: {
                        categories: labels
                    }
                }
            }
        },
        async getSeriesLineRates(province, district, ward) {
            let payload = {
                province,
                district,
                ward
            }
            for (let key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }
            let response = await httpClient.post(`statistic/rates-by-date`, {
                ...payload
            })
            let series = []
            let labels = []
            let revertDate = ""
            if (response.data.data) {
                response.data.data.forEach(el => {
                    let splitDate = (el._id).split("-");
                    revertDate = splitDate[2] + "/" + splitDate[1]
                    series.push(el.count)
                    labels.push(revertDate)
                })
            }
            this.$refs.seriesLineRates.updateSeries([{
                data: series
            }])
            this.lineChartOptions2 = {
                ...this.lineChartOptions2,
                ...{
                    xaxis: {
                        categories: labels
                    }
                }
            }
        },
        async getTotals(province, district, ward) {
            let payload = {
                province,
                district,
                ward
            }
            for (let key in payload) {
                if (payload[key] == null) {
                    delete payload[key]
                }
            }
            let response = await httpClient.post(`statistic/sentiments-rates-total`, {
                ...payload
            })
            this.totalRates = response.data.totalRates || 0
            this.totalSentiments = response.data.totalSentiments || 0
            this.userSentiments = response.data.userSentiments || 0
            this.userSentimentsNoProcessing = response.data.userSentimentsNoProcessing || 0
            this.userSentimentsProcessed = response.data.userSentimentsProcessed || 0
            this.userSentimentsWaiting = response.data.userSentimentsWaiting || 0
            this.userSentimentsProcessing = response.data.userSentimentsProcessing || 0
        },
        async getData(province, district, ward) {
            await this.getTotals(province, district, ward)
            await this.ChartsStatusGroup(province, district, ward)
            await this.getChartsCateGroup(province, district, ward)
            await this.getSeriesLineRates(province, district, ward)
            await this.getSeriesLineSentiments(province, district, ward)
        },
    },
    async created() {
        await this.getData()
    },
}
</script>

<style scoped>
.style-height-total {
    height: 50% !important;
}

.mini-stats-wid {
    height: calc(100% - 24px);
}
@media (max-width: 380px) {
    .style-size {
        font-size: 17px;
    }
}
</style>
